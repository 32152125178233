<template>
  <div class="body-widget-video-wrapper">
    <div class="widget-body__video" :class="[showSquareBorders ? 'show-square-borders' : '']" @click="action">
      <video
        v-if="video"
        id="keyTipVideo"
        muted
        loop
        autoplay
        playsinline
        webkit-playsinline
        class="video-frame"
        :class="[showSquareBorders ? 'show-square-borders' : '']"
      >
        <source :src="video" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script>
import { translate } from "@/services/utils.js";

export default {
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["action"],
  data() {
    return {};
  },
  computed: {
    video() {
      if (!this.widget?.videoUrl) {
        return null;
      }
      return translate(this.widget.videoUrl);
    }
  },
  methods: {
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-video-wrapper {
  padding: 0 16px;
}
.widget-body__video {
  width: 100%;
  flex-basis: 100%;
  height: auto;
  overflow: hidden;
  border-radius: 8px;
}
.video-frame {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
</style>
