<template>
  <div class="body-widget-banner-3-wrapper" @click="action">
    <img class="body-widget-banner-3__image" :src="getBackgroundImage(image, '_appthumb', '_appthumb')" />
    <div class="body-widget-banner-3__overlay" />
    <div class="body-widget-banner-3__content">
      <div class="body-widget-banner-3__content_texts">
        <div class="body-widget-banner-3__content__title">
          {{ title }}
        </div>
        <div v-if="subtitle" class="body-widget-banner-3__content__subtitle">
          {{ subtitle }}
        </div>
      </div>
      <div class="body-widget-banner-button">
        {{ ctaText }}
      </div>
    </div>
  </div>
</template>

<script>
import { getImageUrl, translate } from "@/services/utils.js";
export default {
  props: {
    widget: {
      type: Object,
      required: true
    }
  },
  emits: ["action"],
  computed: {
    title() {
      return translate(this.widget.widgetTitleOptions?.title);
    },
    subtitle() {
      if (!this.widget.widgetTitleOptions?.subtitle) {
        return null;
      }
      return translate(this.widget.widgetTitleOptions.subtitle);
    },
    ctaText() {
      return translate(this.widget.ctaText);
    },
    ctaColor() {
      if (!this.widget.mainColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.mainColor;
    },
    image() {
      return this.widget.photographs ? this.widget.photographs[0] : null;
    },
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../../assets/styleguide.less";
.body-widget-banner-3-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  width: calc(100% - 64px);
  margin-left: 16px;
  flex: 91px 0 0;
  height: 168px;
  position: relative;
  border-radius: 8px;
  background-color: white;
  padding: 16px;
}
.body-widget-banner-3__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
}
.body-widget-banner-3__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background-color: rgba(19, 19, 25, 0.4);
}
.body-widget-banner-3__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 168px;
  z-index: 1;
}
.body-widget-banner-3__content_texts {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.body-widget-banner-3__content__title {
  font-size: 24px;
  line-height: 30px;
  text-align: left;
  color: white;
}
.body-widget-banner-3__content__subtitle {
  .subheader-white;
  margin-top: 6px;
  text-align: left;
}
.body-widget-banner-button {
  .subheader-black;
  .medium;
  padding: 8px 24px;
  text-align: center;
  width: fit-content;
  color: white;
  border: 1px solid white;
  border-radius: 30px;
}
</style>
