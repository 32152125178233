<template>
  <div class="widgets-home-popups-wrapper">
    <profile-popup
      v-if="profilePopupVisible"
      class="animated fadeIn"
      :origin-div="userButtonDiv"
      :show-language-selector-only="true"
      @show-profile="emitShowUserRoute"
      @close="emitHideProfilePopup"
    ></profile-popup>
    <survey-button
      v-show="showSurveyProactive"
      :button-type="surveyButtonTypes.BOTTOM_BANNER"
      :item-type="15"
      :item-id="establishment.id"
      :trigger-type="surveyTriggerTypes.SURVEY_PROACTIVE"
      :has-footer-shortcuts="hasFooterShortcuts"
      @survey-available="onSurveyAvailable"
      @survey-v3-proactive-visible="onSurveyV3ProactiveVisible"
    ></survey-button>
    <message-popup
      v-if="messagePopupVisible"
      style="height: 100%"
      :title="getString('WARNING')"
      :text="errorMessage"
      :action-text="getString('CLOSE')"
      @click="showError = false"
    ></message-popup>
    <service-detail
      v-if="welcomeServiceDetailVisible"
      :service-data="serviceDetailData"
      :style="{ top: welcomeIsActive ? '0' : '100vh' }"
      class="service-detail"
      :class="[showWelcomeAnimation ? 'service-detail-show' : 'service-detail-hide']"
      :from-frontpage="true"
      @close-service-detail="closePopupServiceDetail"
    >
    </service-detail>
    <welcome-popup
      v-if="showWelcomePopup"
      :name="user.name"
      :check-in="user.checkIn"
      :check-out="user.checkOut"
      :show-loading-in-welcome="showLoadingInWelcome"
      @close-welcome-popup="closeWelcomePopup"
    ></welcome-popup>
    <congress-popup
      v-if="showCongressPopup"
      class="congress-popup"
      :is-public="congressRedirectIsPublic"
      :congress-id="new Number(congressId)"
      :title="
        isLogged()
          ? getString('CONGRESS_REDIRECT_TITLE_LOGGED') + ' ' + congressName
          : getString('CONGRESS_REDIRECT_TITLE_NO_LOGGED') + ' ' + congressName
      "
      :text="getString('ENTER_EVENT_CODE')"
      :text2="getString('CONGRESS_ARE_YOU_GUEST')"
      :action-text="getString('EVENT_ENTER_BUTTON')"
      :input-text="getString('EVENT_CODE')"
      :full-name-input-text="getString('FULL_NAME')"
      :cancelar-text="getString('CANCEL')"
      @click="congressFromPopup"
      @cancel-click="showCongressPopup = false"
      @name-added="userNameUpdated"
    ></congress-popup>
    <div v-if="showLoader" class="loading-container"><div class="loader"></div></div>
  </div>
</template>

<script>
import { getImageUrl, getLocale, getString, isCmsMode, enterToEvent, translate, isLogged } from "@/services/utils.js";
import { defineAsyncComponent } from "vue";
import { EventBus } from "@/services/eventBus";
import surveyButtonTypes from "@/components/Surveys/buttonTypes.js";
import surveyTriggerTypes from "@/components/Surveys/triggerTypes.js";
import cloneDeep from "lodash-es/cloneDeep";
import * as types from "@/store/mutation-types";

const SurveyButton = defineAsyncComponent(() => import("@/components/Surveys/SurveyButton.vue"));
const ProfilePopup = defineAsyncComponent(() => import("@/components/Frontpages/Components/FrontpageProfilePopup.vue"));
const MessagePopup = defineAsyncComponent(() => import("@/components/MessagePopup.vue"));
const ServiceDetail = defineAsyncComponent(() => import("@/components/Web.vue"));
const WelcomePopup = defineAsyncComponent(() => import("@/components/WelcomePopup.vue"));
const CongressPopup = defineAsyncComponent(() => import("@/components/Congress/PrivateCongressPopup.vue"));
export default {
  components: {
    ProfilePopup,
    SurveyButton,
    MessagePopup,
    ServiceDetail,
    WelcomePopup,
    CongressPopup
  },
  props: {
    profilePopupVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    welcomeMessageId: {
      type: Number,
      required: false,
      default: null
    },
    hasFooterShortcuts: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ["hideProfilePopup", "showUserRoute", "surveyV3ProactiveVisible", "showLogin", "refreshAllSurveyData"],
  data() {
    return {
      userButtonDiv: {
        y: 76,
        "margin-right": 16
      },
      surveyButtonTypes,
      surveyTriggerTypes,
      surveyProactiveAvailable: false,
      showSurveyButton: false,
      showError: false,
      errorMessage: null,
      serviceDetailData: null,
      showServiceDetail: false,
      welcomeIsActive: false,
      showWelcomeAnimation: false,
      showWelcome: false,
      showLoadingInWelcome: false,
      showCongressPopup: false,
      showLoader: false,
      congressRedirectIsPublic: false,
      congressName: ""
    };
  },
  computed: {
    strings() {
      return this.$store.state.strings;
    },
    user() {
      return this.$store.state.user;
    },
    establishment() {
      return this.$store.state.establishment;
    },
    isLobby() {
      return this.$store.state.isLobby;
    },
    showSurveyProactive() {
      return this.surveyProactiveAvailable && this.showSurveyButton && !this.isLobby;
    },
    messagePopupVisible() {
      return this.showError && this.errorMessage?.length > 0;
    },
    welcomeServiceDetailVisible() {
      return this.serviceDetailData && this.showServiceDetail && !isCmsMode();
    },
    pendingTermsConditions() {
      return this.$store.state.pendingTermsConditions;
    },
    showWelcomePopup() {
      return this.showWelcome || this.pendingTermsConditions[this.establishment.id] === true;
    },
    establishmentHash() {
      return this.$store.state.establishmentHash;
    },
    congressId() {
      return this.$store.state.congressIdRedirect;
    },
    eventPreLogin() {
      return this.$store.state.eventPreLogin;
    }
  },
  watch: {
    welcomeMessageId: function () {
      this.getWelcomeServices();
    }
  },
  created() {
    this.manageEventBus();
    this.getWelcomeServices();
    this.manageCongressPopupIfParamPresent();
  },
  methods: {
    getImageUrl,
    isLogged,
    manageEventBus() {
      EventBus.$off("showSurveyProactive");
      EventBus.$on("showSurveyProactive", () => {
        this.surveyProactiveAvailable = true;
      });
    },
    getString(key) {
      const user = cloneDeep(this.user);
      user.locale = getLocale();
      return getString(this.strings, key, user);
    },
    emitHideProfilePopup() {
      this.$emit("hideProfilePopup");
    },
    emitShowUserRoute() {
      this.$emit("showUserRoute");
    },
    onSurveyAvailable() {
      this.showSurveyButton = true;
    },
    onSurveyV3ProactiveVisible() {
      this.$emit("surveyV3ProactiveVisible");
    },
    async getWelcomeServices() {
      if (this.$store.state.welcomeShow[this.establishmentHash] === false || !this.welcomeMessageId) {
        return;
      }
      const response = await this.$store.dispatch("getService", {
        serviceId: this.welcomeMessageId,
        serviceType: "welcome_message"
      });

      if (!response) {
        return console.error("Error getting welcome service with id ", this.welcomeMessageId);
      }

      const delay = this.getDelay();

      setTimeout(() => {
        this.serviceDetailData = response;
        this.showServiceDetail = true;

        if (this.welcomeIsActive) {
          this.showWelcomeAnimation = true;
          return;
        }

        const payload = { [this.establishmentHash]: true };
        this.$store.dispatch("setWelcomeShowActive", payload);
        setTimeout(() => {
          this.showWelcomeAnimation = true;
          this.$store.dispatch("setStatuswelcomeShowVisible", payload);
          this.welcomeIsActive = true;
        }, 1000);
      }, delay);
    },
    getDelay() {
      const params = this.$router.currentRoute.value.query;
      let delay = 0;
      if (
        params?.ref?.indexOf("_DL") !== -1 &&
        params?.ref?.split("_")[1] &&
        params?.ref?.split("_")[1].indexOf("DL") === -1
      ) {
        delay = 4500;
      }
      return delay;
    },
    closePopupServiceDetail() {
      this.showWelcomeAnimation = false;
      this.welcomeIsActive = false;

      const payload = { [this.establishmentHash]: false };
      setTimeout(() => {
        this.serviceDetailData = false;
        this.showServiceDetail = false;
        this.$store.dispatch("setAppWelcomeShow", payload);
        this.$store.dispatch("setWelcomeShowActive", payload);
        this.$store.dispatch("setStatuswelcomeShowVisible", payload);
      }, 700);
    },
    closeWelcomePopup() {
      this.showWelcome = false;

      EventBus.$emit("displayWelcomePopup", this.showWelcome);

      this.$store.dispatch("setPendingTermsConditions", {
        establishment: this.$store.state.establishment.id,
        value: false
      });
    },
    manageCongressPopupIfParamPresent() {
      if (this.eventPreLogin?.code && this.user && this.isLogged(this.user)) {
        this.linkCongressByCode(false, this.eventPreLogin.code);
        this.$store.commit(types.ADD_EVENT_PRE_LOGIN, false);
        return;
      }
      this.$store.commit(types.ADD_EVENT_PRE_LOGIN, false);
      EventBus.$off("listenerCongressUrlParam");
      EventBus.$on("listenerCongressUrlParam", result => {
        this.showLoader = true;
        this.checkCongressPopup();
      });

      EventBus.$emit("listenerCongressUrlParamReady");
    },
    async checkCongressPopup() {
      this.showCongressPopup = false;
      if (!this.congressId) {
        return;
      }
      const response = await this.$store.dispatch("getCongress", this.congressId);
      if (!response.translatableName) {
        console.error("Error in get congress: ");
        this.showLoader = false;
      }
      const nameCongressRedirect = translate(response.translatableName, this.user);

      if ((!response.hasOwnProperty("congressCode") || !response.congressCode) && response.isPublic) {
        this.congressRedirectIsPublic = true;
      } else {
        this.congressRedirectIsPublic = false;
      }

      this.congressName = nameCongressRedirect;
      this.showCongressPopup = true;
      this.showLoader = false;
    },
    async userNameUpdated(data) {
      this.showCongressPopup = false;
      if (this.user && this.isLogged(this.user)) {
        this.linkCongressByCode(true, data.code);
        return;
      }
      if (data.loginType == 1) {
        this.$store.commit(types.ADD_EVENT_PRE_LOGIN, { ...data, congressName: this.congressName });
        this.$emit("showLogin");
        return;
      }
      if (!data.fullName) {
        this.showErrorMessage("EVENT_ERROR_FULLNAME");
        this.showCongressPopup = false;
        return;
      }
      this.showLoader = true;
      let userData = null;
      try {
        userData = await this.$store.dispatch("updateUserData", data.fullName);
      } catch (error) {
        this.showLoader = false;
        this.showErrorMessage("EVENT_ERROR_FULLNAME");
      }
      if (!userData) {
        return;
      }
      this.linkCongressByCode(false, data.code);
    },
    async congressFromPopup(data) {
      if (!data || !data.code) {
        this.showCongressPopup = false;
        this.showErrorMessage("EVENT_ERROR_CODE");
        return;
      }
      if (this.user && this.isLogged(this.user) && data.hasOwnProperty("codeIsCongressId") && data.codeIsCongressId) {
        this.linkCongressByCode(true, data.code);
        return;
      }
      if (this.user && this.isLogged(this.user)) {
        this.linkCongressByCode(false, data.code);
        return;
      }
      if (data.loginType == 1) {
        this.$store.commit(types.ADD_EVENT_PRE_LOGIN, { ...data, congressName: this.congressName });
        this.$emit("showLogin");
        return;
      }
      if (data.fullName === "") {
        this.showErrorMessage("EVENT_ERROR_FULLNAME");
        return;
      }
      this.showLoader = true;
      let userData = null;
      try {
        userData = await this.$store.dispatch("updateUserData", data.fullName);
      } catch (error) {
        this.showLoader = false;
        this.showErrorMessage("EVENT_ERROR_FULLNAME");
      }
      if (!userData) {
        return;
      }
      this.linkCongressByCode(false, data.code);
    },
    showErrorMessage(error) {
      this.showError = true;
      this.errorMessage = this.getString(error);
    },
    async linkCongressByCode(isPublic, congressIdOrCode) {
      this.showLoader = true;
      let response = null;
      try {
        response = await this.$store.dispatch("linkCongress", { codeOrId: congressIdOrCode, isPublic: isPublic });
      } catch (error) {
        console.error("Error in link congress: ");
        this.showCongressPopup = false;
        this.showLoader = false;
        this.showErrorMessage("ERROR_PLEASE_TRY_AGAIN");
      }
      if (!response?.congress) {
        return;
      }
      const idCongress = response.congress;
      let responseCongress = null;
      try {
        responseCongress = await this.$store.dispatch("getCongress", idCongress);
      } catch (error) {
        console.error("Error in get congress: ", error);
        this.showCongressPopup = false;
        this.showLoader = false;
        this.showErrorMessage("ERROR_PLEASE_TRY_AGAIN");
        return;
      }
      if (!responseCongress) {
        return;
      }
      this.$store.commit(types.NEW_CONGRESS, true);
      this.$store.commit(types.EVENTS, responseCongress);
      this.$store.commit(types.NEW_EVENT_ID, responseCongress.id);

      this.showCongressPopup = false;
      this.showLoader = false;
      enterToEvent(responseCongress.id);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../assets/loader.css";
.service-detail {
  z-index: 10001 !important; //under 10002 of enquiries
  height: 100vh;
  width: 100%;
  position: fixed;
  //top: 100vh;
  opacity: 0;
  background-color: white;
  transition: all 0.4s ease;
  pointer-events: none;
  &.service-detail-show {
    top: 0;
    opacity: 1;
    pointer-events: auto;
    height: 100vh;
    overflow: scroll;
  }
  &.service-detail-hide {
    top: 100vh;
    opacity: 0;
    pointer-events: none;
  }
}
.congress-popup {
  z-index: 10000 !important;
}
.loading-container {
  height: 100%;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
  z-index: 10001 !important;
}
.loader {
  border: 3px solid #697181;
  border-radius: 50%;
  border-left-color: #ebedf0;
  width: 54px;
  height: 54px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
</style>
