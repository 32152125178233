<template>
  <div class="c-body-widget-banner-card" :class="[showSquareBorders ? 'show-square-borders' : '']" @click="action">
    <img
      class="c-body-widget-banner-card__image"
      :src="getBackgroundImage(image, '_appthumb', '_appthumb')"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div class="c-body-widget-banner-card__content">
      <div class="c-body-widget-banner-card__content__title">
        {{ title }}
      </div>
      <div class="c-body-widget-banner-card__content__link">
        {{ ctaText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getImageUrl, translate } from "@/services/utils.js";
import { bannerStyles } from "@/utils/enums/widgetsFrontpage.js";
import type { PropType } from "vue";

interface BodyWidgetBannerCard {
  place: number;
  style: number;
  hidden: boolean;
  itemId: number;
  ctaText?: { [key: string]: string };
  ctaColor?: string;
  itemType: number;
  cmsModule: number;
  mainColor: string;
  templateId: number;
  photographs: number[];
  backgroundColor: string;
  bubbleContentType: number;
  widgetTitleOptions: {
    title: { [key: string]: string };
    active: boolean;
    color?: string;
  };
}

export default {
  props: {
    widget: {
      type: Object as PropType<BodyWidgetBannerCard>,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {
      bannerStyles
    };
  },
  computed: {
    title() {
      return translate(this.widget.widgetTitleOptions?.title);
    },
    ctaText() {
      return translate(this.widget.ctaText);
    },
    ctaColor() {
      if (!this.widget.mainColor) {
        return this.$store.state.mainColor;
      }
      return this.widget.mainColor;
    },
    backgroundColor() {
      return this.widget?.backgroundColor || "white";
    },
    image() {
      return this.widget.photographs ? this.widget.photographs[0] : null;
    },

    cssOptions() {
      return {
        backgroundColor: this.backgroundColor,
        title: {
          color: this.widget.widgetTitleOptions?.color || "#34323D"
        },
        cta: {
          color: this.widget.ctaColor || "#34323D"
        }
      };
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../../assets/styleguide.less";

.c-body-widget-banner-card {
  box-sizing: border-box;

  display: grid;
  grid-template-columns: 90px 1fr;
  justify-content: flex-start;
  gap: @size-base*4;

  position: relative;
  box-shadow: 0 3px 14px 0 rgba(52, 50, 61, 0.1);
  border-radius: 4px;
  background-color: v-bind("cssOptions.backgroundColor");
  padding: @size-base*4;

  &__image {
    width: 100%;
    aspect-ratio: 1;

    border-radius: 4px;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__title {
      font-size: var(--Size-Title-Small, 18px);
      font-style: normal;
      font-weight: var(--Weight-Title, 600);
      line-height: var(--Line-height-Title-Small, 24px); /* 133.333% */
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;

      color: v-bind("cssOptions.title.color");
    }

    &__link {
      .subheader-black;
      .medium;

      color: v-bind("cssOptions.cta.color");
    }
  }
}

.show-square-borders {
  border-radius: 0 !important;
}
</style>
