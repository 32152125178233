<template>
  <div
    class="header-widget-mini"
    :class="{
      logged: isLogged(user),
      'extra-padding-top': $store.state.embedSafearea > 0,
      'no-left-button': !showExitButton && !showFrontSideMenuButton
    }"
    :style="{ 'background-color': backgroundColor }"
  >
    <div
      v-if="showExitButton"
      class="header-widget-mini-button exit"
      :style="{ 'background-color': buttonsBackgroundColor }"
      @click="emitExitEstablishmentEventoToEmbedded"
    >
      <stay-ui-icon icon="logout-02" :color="iconsColor" :size="20" variant="stroke" type="rounded" />
    </div>
    <div
      v-else-if="showFrontSideMenuButton"
      class="header-widget-mini-button"
      :style="{ 'background-color': buttonsBackgroundColor }"
      @click="showFrontSideMenu"
    >
      <stay-ui-icon icon="menu-01" :color="iconsColor" :size="20" variant="stroke" type="rounded" />
    </div>
    <div v-else></div>
    <div
      v-if="titleType === miniHeaderTitleTypes.MINI_HEADER_TITLE_TYPE_TEXT"
      class="header-widget-mini__headline"
      :style="{ color: headlineColor }"
    >
      {{ headlineText }}
    </div>
    <img
      v-else-if="titleType === miniHeaderTitleTypes.MINI_HEADER_TITLE_TYPE_LOGO && frontpageIcon"
      class="header-widget-mini__logo"
      :src="getImageUrl(frontpageIcon, true)"
    />
    <div class="header-widget-mini__action-buttons">
      <div
        v-if="!isLogged(user)"
        class="header-widget-mini-button"
        :style="{ 'background-color': buttonsBackgroundColor }"
        @click="showUserProfile"
      >
        <stay-ui-icon :icon="userIcon" :color="iconsColor" :size="20" variant="stroke" type="rounded" />
      </div>
      <div
        v-else
        class="header-widget-mini-button-logged"
        :style="{ 'background-color': buttonsBackgroundColor }"
        @click="showUserProfile"
      >
        <stay-ui-icon icon="door-02" :color="iconsColor" :size="20" variant="stroke" type="rounded" />
        <div v-if="userRoom" class="header-widget-mini-user-room caption-black semibold" :style="{ color: iconsColor }">
          {{ userRoom }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isLogged, isFullEmbedPWA, isMonoHotelApp, translate, getImageUrl } from "@/services/utils.js";
import { headlineModes, miniHeaderTitleTypes } from "@/utils/enums/widgetsFrontpage.js";
import { StayUiIcon } from "@stay/ui";
const HEADER_MINI_HEIGHT = 80;
export default {
  components: { StayUiIcon },
  props: {
    widget: {
      type: Object,
      required: true
    },
    frontpageData: {
      type: Object || null,
      required: false
    }
  },
  emits: ["showUserProfile", "showFrontSideMenu", "height"],
  data() {
    return {
      miniHeaderTitleTypes
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    establishment() {
      return this.$store.state.establishment;
    },
    userName() {
      return this.user?.name;
    },
    userRoom() {
      return this.user?.room;
    },
    showUserButton() {
      return this.widget.showUserButton;
    },
    headlineText() {
      if (this.widget?.headlineMode === headlineModes.HEADLINE_MODE_PROPERTY_NAME) {
        return this.establishment.name;
      }
      return translate(this.widget.translatableHeadline);
    },
    titleType() {
      return this.widget.miniHeaderTitleType || miniHeaderTitleTypes.MINI_HEADER_TITLE_TYPE_TEXT;
    },
    headlineColor() {
      return this.widget.headlineColor || "white";
    },
    backgroundColor() {
      return this.widget.backgroundColor;
    },
    iconsColor() {
      return this.widget.iconsColor;
    },
    buttonsBackgroundColor() {
      return this.widget.buttonsBackgroundColor;
    },
    showExitButton() {
      return isFullEmbedPWA() && !this.showFrontSideMenuButton && !isMonoHotelApp();
    },
    showFrontSideMenuButton() {
      return this.frontpageData?.frontSideMenu?.showSideMenu;
    },
    frontpageIcon() {
      const url_string = window.location.href;
      const url = new URL(url_string);
      if (url.searchParams.get("icon")) {
        return url.searchParams.get("icon");
      }
      return this.widget.frontpageIcon;
    },
    isLobby() {
      return this.$store.state.isLobby;
    },
    userIcon() {
      return this.isLobby ? "earth" : "user";
    }
  },
  created() {
    this.$emit("height", HEADER_MINI_HEIGHT + this.$store.state.embedSafearea);
  },
  methods: {
    isLogged,
    getImageUrl,
    showUserProfile() {
      this.$emit("showUserProfile");
    },
    emitExitEstablishmentEventoToEmbedded() {
      window.location.href = "https://exit-establishment";
    },
    showFrontSideMenu() {
      this.$emit("showFrontSideMenu");
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide";

.header-widget-mini {
  position: fixed;
  height: calc(var(--embed-top-safearea) + @size-base*4 + 80);
  width: 100%;
  display: grid;
  grid-template-columns: 40px auto 40px;
  // display: flex;
  justify-content: space-between;
  align-items: start;
  background-color: white;
  z-index: 2;

  padding: @size-base*4;
  padding-top: calc(var(--embed-top-safearea) + @size-base*4);
  background-color: @color-white;
  box-shadow: 0 16px 32px 0 rgba(52, 50, 61, 0.1);
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
  &.logged {
    grid-template-columns: 60px auto 60px;
  }
  &.extra-padding-top {
    padding-top: calc(var(--embed-top-safearea) + @size-base*5.5);
    padding-bottom: @size-base*2.5;
  }

  &__headline {
    .u-text__headline--4;
    text-align: center;
    align-self: center;
  }
  &__logo {
    height: 48px;
    width: 160px;
    z-index: 2;
    object-fit: contain;
  }
}
.header-widget-mini-button {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  &.exit {
    transform: rotate(180deg);
  }
}
.header-widget-mini-button-logged {
  border-radius: 4px;
  height: 48px;
  width: 60px;
  flex-basis: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
.header-widget-mini-user-room {
  margin-top: 2px;
  max-width: calc(100% - 12px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
