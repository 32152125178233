<template>
  <div class="c-body-widget-banner-image" @click="action">
    <div class="c-body-widget-banner-image__content">
      <div class="c-body-widget-banner-image__content__titles">
        <div class="c-body-widget-banner-image__content__title">
          {{ title }}
        </div>

        <div v-if="subTitle" class="c-body-widget-banner-image__content__subtitle">
          {{ subTitle }}
        </div>
      </div>

      <div v-if="ctaText" class="c-body-widget-banner-image__content__button">
        {{ ctaText }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getImageUrl, translate } from "@/services/utils.js";
import type { PropType } from "vue";

interface BodyWidgetBannerImage {
  place: number;
  style: number;
  hidden: boolean;
  itemId: number;
  ctaText: { [key: string]: string };
  ctaColor?: string;
  itemType: number;
  cmsModule: number;
  mainColor: string;
  templateId: number;
  photographs: number[];
  backgroundColor: string;
  bubbleContentType: number;
  widgetTitleOptions?: {
    title: { [key: string]: string };
    active: boolean;
    size?: number;
  };
  widgetSubTitleOptions?: {
    title: { [key: string]: string };
    size?: number;
  };
  height?: number;
}

export default {
  props: {
    widget: {
      type: Object as PropType<BodyWidgetBannerImage>,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  computed: {
    title(): string {
      return translate(this.widget.widgetTitleOptions?.title);
    },
    subTitle(): string {
      const text = translate(this.widget.widgetSubTitleOptions?.title);
      return text.length > 1 ? text : null;
    },
    ctaText(): string {
      const text = translate(this.widget.ctaText);
      return text.length > 1 ? text : null;
    },
    image() {
      return this.widget.photographs[0] || null;
    },

    cssOptions() {
      const url = this.getImageUrl(this.image, "_appthumb", "_appthumb");

      return {
        height: `${this.widget.height ?? 200}px`,
        image: `linear-gradient(rgba(19, 19, 25, 0.4) 0%, rgba(19, 19, 25, 0.4) 100%), url(${url}) center center / cover no-repeat`,
        borderRadius: !this.showSquareBorders ? "var(--8, 8px)" : 0,

        title: {
          size: `${this.widget.widgetTitleOptions?.size ?? 24}px`
        },
        subTitle: {
          size: `${this.widget.widgetSubTitleOptions?.size ?? 16}px`
        },
        cta: {
          color: this.widget.ctaColor || "white",
          backgroundColor: this.widget.mainColor || "var(--main-color)"
        }
      };
    }
  },
  methods: {
    getImageUrl,
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../../assets/styleguide.less";

.c-body-widget-banner-image {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: v-bind("cssOptions.height");
  min-height: fit-content;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: @size-base*8 @size-base*6;
  border-radius: v-bind("cssOptions.borderRadius");
  background: v-bind("cssOptions.image");

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: @size-base*4;
    z-index: 1;

    &__titles {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: @size-base*2;
    }

    &__title {
      font-size: v-bind("cssOptions.title.size");
      line-height: normal;
      text-align: center;
      color: white;
    }

    &__subtitle {
      font-size: v-bind("cssOptions.subTitle.size");
      line-height: normal;
      text-align: center;
      color: white;
    }

    &__button {
      .subheader-black;
      .medium;
      padding: @size-base*2 @size-base*6;
      text-align: center;
      width: fit-content;
      border-radius: 30px;

      color: v-bind("cssOptions.cta.color");
      background-color: v-bind("cssOptions.cta.backgroundColor");
    }
  }
}
</style>
