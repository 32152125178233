<template>
  <div class="body-widget-banner-wrapper">
    <component :is="getBannerComponent" :widget="widget" :show-square-borders="showSquareBorders" @action="action" />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { translate } from "@/services/utils.js";
import { banners } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetBanner1: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/Banners/BodyWidgetBanner1.vue")
    ),
    BodyWidgetBanner2: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/Banners/BodyWidgetBanner2.vue")
    ),
    BodyWidgetBanner3: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Cards/Banners/BodyWidgetBanner3.vue")
    )
  },
  props: {
    widget: {
      type: Object,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    }
  },
  emits: ["action"],
  data() {
    return {};
  },
  computed: {
    text() {
      if (!this.widget?.widgetTitleOptions?.title) {
        return "";
      }
      return translate(this.widget.widgetTitleOptions.title);
    },
    getBannerComponent() {
      if (!this.widget?.style) return null;
      return banners[this.widget.style];
    }
  },
  methods: {
    action() {
      this.$emit("action", this.widget);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";

.body-widget-banner-wrapper {
  padding: 0 @size-base*4;
}
</style>
